import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout-scene"
import SEO from "../components/seo"

import { rhythm } from '../utils/typography'
import styled from "@emotion/styled";
import Scene from "../components/scenes/scene3";



const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)}; 
    }
    div {
      align-items: center !important;
    }
  }
  
`



const SecondPage = () => (
  <Layout>
    <SEO title="Projects" />
      <div style={{position:`relative`, margin: `0`, padding:`0`, border:`0`}}>
            <Scene />
      </div>
  </Layout>
)

export default SecondPage
