import React, { useRef } from 'react';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { ExecuteCodeAction } from '@babylonjs/core/Actions';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {GlowLayer, InterpolateValueAction, StandardMaterial, Texture} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {PointLight} from "@babylonjs/core";

import {Mesh} from "@babylonjs/core";  // ???

import {ArcRotateCamera} from "@babylonjs/core";
import {SceneLoader} from "@babylonjs/core";

let box;
let babylonLink;

let box1;

const onSceneReady = scene => {

    // scene.clearColor = new Color3(0.5, 0.8, 0.5);
    scene.clearColor = new Color3(1, 1, 1); // CHECK
    // scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375);


    // This creates and positions a free camera (non-mesh)
    //var camera = new FreeCamera("camera1", new Vector3(0, 10, -10), scene);
    // This targets the camera to scene origin
    //camera.setTarget(Vector3.Zero());

    // Create a camera which rotates around the object
    var camera = new ArcRotateCamera("camera", 0, Math.PI/2, 15, Vector3.Zero(), scene);

    scene.collisionsEnabled = true;
    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    camera.lowerRadiusLimit = 3;
    camera.upperRadiusLimit = 22;
    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);


    var light0 = new HemisphericLight("hemiLight", new Vector3(-1, 1, 0), scene);
    light0.diffuse = new Color3(1, 0, 0);
    light0.specular = new Color3(0, 1, 0);
    light0.groundColor = new Color3(0, 1, 0);


    const assumedFramesPerSecond = 60;
    const earthGravity = -9.81;
    scene.gravity = new Vector3(0, earthGravity / assumedFramesPerSecond, 0);



    box = MeshBuilder.CreateBox("box", {size: 0.7}, scene);
    box.position.y = 2;

    box1 = MeshBuilder.CreateBox("box1", {size: 0.7}, scene);
    box1.position.y = 0;


    box.checkCollisions =true;

    // Creating main material
    var boxMaterial = new StandardMaterial("material", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    boxMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);

    var boxMaterial1 = new StandardMaterial("material1", scene);
    boxMaterial1.emissiveColor = new Color3(0, 0.58, 0.86);

    // Assigning material
    box.material = boxMaterial1;

    var gl = new GlowLayer("glow", scene);
    gl.intensity = 0.77;



    var boxes = [];
    for(var i = 0; i < 500; i++) {
        boxes[i] = box1.clone("box" +i);
        boxes[i].position.x = -2 + i%5;
        boxes[i].position.y = 2 - Math.floor(i/10);
        boxes[i].position.z = 2 - Math.floor(i/5);

        var instance =box1.createInstance("box" +i);
        instance.position.x = -2 - i%5;
        instance.position.y = 2 + Math.floor(i/10);
        instance.position.z = 2 + Math.floor(i/5);
    }


    // var skybox = Mesh.CreateBox("BackgroundSkybox", 500, scene, undefined, Mesh.BACKSIDE);

    scene.createDefaultSkybox(scene.environmentTexture, false, 1000, 0, false);


    // Register click event on box mesh
    box.actionManager = new ActionManager(scene);
    box.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                babylonLink.current.click()
            }
        )
    );


    // Our built-in 'ground' shape.
    //MeshBuilder.CreateGround("ground", {width: 6, height: 6}, scene);
}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
    if (box !== undefined) {
        var deltaTimeInMillis = scene.getEngine().getDeltaTime();

        const rpm = 10;
        box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

    }
}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.co/">
            </a>
        </>
    )
}
